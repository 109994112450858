import request from '@/utils/request'


// 查询店铺-分类列表
export function listStoreType(query) {
  return request({
    url: '/platform/store-type/list',
    method: 'get',
    params: query
  })
}

// 查询店铺-分类分页
export function pageStoreType(query) {
  return request({
    url: '/platform/store-type/page',
    method: 'get',
    params: query
  })
}

// 查询店铺-分类详细
export function getStoreType(data) {
  return request({
    url: '/platform/store-type/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺-分类
export function addStoreType(data) {
  return request({
    url: '/platform/store-type/add',
    method: 'post',
    data: data
  })
}

// 修改店铺-分类
export function updateStoreType(data) {
  return request({
    url: '/platform/store-type/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺-分类
export function delStoreType(data) {
  return request({
    url: '/platform/store-type/delete',
    method: 'post',
    data: data
  })
}
